import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Hero from "./hero"
import Container from "components/container"

const StyledFaqList = styled.section`
	padding: 20px 0px 100px;
	background-color: ${colors.white};
	border-bottom-width: 0;
	min-height: 300px;
	max-width: 1000px;
	margin: 0px auto;
	padding-left: 0px;

	${breakpoint.medium`
		min-height: 600px;
		padding-left: 10px;
	`}  

	h2 {
		font-size: 35px;
		display: none;
		padding-top: 50px;
		padding-left: 10px;
		padding-bottom: 5px;
		border-bottom: 1px solid ${colors.purpleDark};

		&.visible {
			display: block;
		}
	}

`

const StyledFaqAnswer = styled.div`
  width: 94%;
  text-align: left;
  display: block;
  
	${breakpoint.small`
		width: 100%;
	`}		


	.fullfaq {
		display: none;
		flex-wrap: nowrap;
		padding-top: 30px;
		
		${breakpoint.small`
			padding-top: 50px;
		`}		

		&.visible {
			display: flex;
			flex-wrap: nowrap;
			}
		}

	.question-details {
		width: 95%;
		flex-wrap: wrap;
		padding-left: 0px;

	${breakpoint.small`
		padding-left: 10px;
	`}		

		${breakpoint.extraLarge`
			padding-left: 0px;
		`}
		
	}
	
	.question {
		width: 90%;
		text-align: left;
	    font-weight: 600;
	    line-height: 1.4em;

		${breakpoint.small`
			width: 95%;
		`}		
	}
	
	  .answer-details {
		margin-right: 10px;
		opacity: 0;
		visibility: hidden;
		display: block;
		transform-origin: top;
		transform:scale(1,0);
		transform-origin: top;
		-webkit-transition: all 1ms ease-in-out;
		transition: all 1ms ease-in-out;
		height: 0px;
		font-size: 15px;

		${breakpoint.small`
			margin-right: 160px;
		`}

		${breakpoint.medium`
			font-size: 18px;
			margin-right: 120px;
		`}

			&.active {
				opacity: 1;
				visibility: visible;
				display: block;
				transform-origin: top;
				transform:scale(1,1);
				-webkit-transition: all 100ms ease-in;
				transition: all 100ms ease-in;
				transition: opacity 400ms ease-in 100ms;
				height: auto;
			}
		
			p {
				margin-top: 10px;
				
				${breakpoint.medium`
					margin-top: 15px;
				`}
			}
		
			a {
				color: ${colors.orange};

				&:hover,
				&.active {
				  color: ${colors.purpleDark};
				}
			}
		
			ul {
				margin: 15px 0px 15px 20px;
				list-style: disc;

				${breakpoint.medium`
					margin: 15px 0px 15px 30px;
				`}
			}
		
			ol {
				margin: 15px 0px 15px 20px;
				list-style: revert;
				
				${breakpoint.medium`
					margin: 15px 0px 15px 30px;
				`}
			}
	  }

	.buttoncolumn { width: 5%; }

     .faqbutton {
		color: ${colors.purpleDark};
		border: ${colors.purpleDark} 1px solid;
		background: ${colors.white};
		width: 40px;
		height: 40px;
		font-size: 1.5em;
		position: relative;
		outline: 0;
		padding: 0;
		border-radius: 50px;

		&:focus {
			outline: 1px solid ${colors.orange};
		}
		
      }
      
		.plus,
		.minus {
			position: absolute;
			transition: 0.5s all ease-out;
			background: ${colors.purpleDark};
		}

		.plus {
			top: 24%;
			bottom: 24%;
			width: 5%;
			left: 48%;
			background: ${colors.purpleDark};
			opacity: 1;
			transform: rotate(0deg);
			color: transparent;
		}

		.faqbutton.active .plus {
			opacity: 0;
			transform: rotate(90deg);
		}

		.minus {
			left: 23%;
			right: 23%;
			height: 5%;
			top: 48%;
			background: ${colors.purpleDark};
			color: transparent;
		}

`

const FaqAnswer = props => (
	<StyledFaqAnswer id={props.slug}>
		<div className="fullfaq visible" data-filter={props.category} data-filter-type="faqs">
			<div
				className="question-details"
				itemScope
				itemProp="mainEntity"
				itemType="https://schema.org/Question"
			>
				<h3
					className="question"
					itemProp="name">
					{props.name}
				</h3>
				<div
					itemScope
					itemProp="acceptedAnswer"
					itemType="https://schema.org/Answer"
				>
					<div
						className="answer-details"
						data-filter
						data-filter-type="single"
						data-target={props.slug}
						dangerouslySetInnerHTML={{ __html: props.answer }}
						itemProp="text"
					>
					</div>
				</div>
			</div>
			<div className="buttoncolumn">
				<button className="faqbutton"
					data-filter
					data-filter-type="single"
					data-target={props.slug}
					onClick={() => props.toggleFunction("single", props.slug)}
				>
					<span className="plus">+</span>
					<span className="minus">&ndash;</span>
				</button>
			</div>
		</div>
	</StyledFaqAnswer>
)

const FaqList = props => {
	const data = useStaticQuery(graphql`
    {
      allWpFaq(sort: {fields: faqs___order, order: ASC}) {
          nodes {
            name: title
            slug
            id
            faqs {
              answer
              category
              order
            }
          }
        }
      }
  `)
	return (
		<StyledFaqList>
			<Container>
				<div
					itemScope
					itemType="https://schema.org/FAQPage"
				>
					<div data-filter="sexual-health" data-filter-type="faqs">
						<h2 data-filter="sexual-health" data-filter-type="faqs" className="visible">Sexual Health Test</h2>
						{data.allWpFaq.nodes.map(faq => {
							if (faq.faqs.category === "sexual-health") {
								return (
									<FaqAnswer
										key={faq.id}
										name={faq.name}
										answer={faq.faqs.answer}
										category={faq.faqs.category}
										slug={faq.slug}
										order={faq.faqs.order}
										toggleFunction={props.toggleFunction}
									/>
								)
							} else {
								return null
							}
						})}
					</div>
					<div data-filter="respiratory" data-filter-type="faqs">
						<h2 data-filter="respiratory" data-filter-type="faqs" className="visible">Respiratory Health Test</h2>
						{data.allWpFaq.nodes.map(faq => {
							if (faq.faqs.category === "respiratory") {
								return (
									<FaqAnswer
										key={faq.id}
										name={faq.name}
										answer={faq.faqs.answer}
										category={faq.faqs.category}
										slug={faq.slug}
										order={faq.faqs.order}
										toggleFunction={props.toggleFunction}
									/>
								)
							} else {
								return null
							}
						})}
					</div>
					<div data-filter="support" data-filter-type="faqs">
						<h2 data-filter="support" data-filter-type="faqs" className="visible">Support</h2>
						{data.allWpFaq.nodes.map(faq => {
							if (faq.faqs.category === "support") {
								return (
									<FaqAnswer
										key={faq.id}
										name={faq.name}
										answer={faq.faqs.answer}
										category={faq.faqs.category}
										slug={faq.slug}
										order={faq.faqs.order}
										toggleFunction={props.toggleFunction}
									/>
								)
							} else {
								return null
							}
						})}
					</div>
				</div>

			</Container>
		</StyledFaqList>
	)
}

class Faqs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: "all",
			isAnswerExpanded: false,
		}
	}

	componentDidMount() {
		var filtby = this.state.filter;
		if (this.props.filterby)
			filtby = this.props.filterby;

		this.handleFilterChange("faqs", filtby, null);
	}

	handleFilterChange = (type, value, event) => {
		const inactiveFilters = document.querySelectorAll("[data-filter]")
		inactiveFilters.forEach(filter => {
			filter.classList.remove("active")
		})

		const activeFilter = document.querySelector(
			"[data-filter][data-target='" + value + "']"
		)
		activeFilter.classList.add("active")

		if (value === "all") {
			const elementsToFilter = document.querySelectorAll(
				"[data-filter-type='" + type + "']"
			)

			elementsToFilter.forEach(post => {
				post.classList.add("visible")
			})
		} else {
			const elementsToFilter = document.querySelectorAll(
				"[data-filter-type='" + type + "'][data-filter='" + value + "']"
			)
			const previousElements = document.querySelectorAll(
				"[data-filter-type='" + type + "']"
			)

			previousElements.forEach(post => {
				post.classList.remove("visible")
			})

			elementsToFilter.forEach(post => {
				post.classList.add("visible")
			})
		}
	}

	handleToggleChange = (type, value, event) => {

		const activeFilters = document.querySelectorAll(
			"[data-filter][data-target='" + value + "']"
		)
		activeFilters.forEach(filter => {
			filter.classList.toggle("active")
		})
	}


	render = () => (
		<React.Fragment>
			<Hero filterFunction={this.handleFilterChange} />
			<FaqList toggleFunction={this.handleToggleChange} />
		</React.Fragment>
	)
}

export default Faqs
